import React from "react";

import Layout from "../components/layout";
import Carousel from 'react-material-ui-carousel';

import slide1 from '../images/slider_1.jpg';
import slide2 from '../images/slider_2.jpg';
import slide3 from '../images/slider_3.jpg';

function IndexPage() {
  var images = [
    slide1,
    slide2,
    slide3
  ];

  return (
    <Layout name="home">
      <Carousel indicators={false}>
        {
          images.map( (item, i) => 
            <div className="h-screen flex justify-center items-center" style={{
                            backgroundImage: `url(${item})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                          }} key={i}>
                <div className="p-10 leading-loose text-center text-xl sm:text-3xl md:text-5xl bg-opacity-50 bg-black w-1/2 front-text-color">
                  Bem vindo ao jardim de infância São Vicente!
              </div>
            </div>
          )
        }
      </Carousel>
    </Layout>
  );
}

export default IndexPage;
